.main-page {
	width: 1920px;
	height: 1080px;
	overflow: hidden;
	position: relative;

	.game-page {
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.sidebar {
		position: absolute;
		bottom: 0;
		right: 0;
	}

	.quiz-screen {
		width: 100%;
		height: 100%;
		font-family: "Helvetica Neue", sans-serif;

		.media {
			display: none;
		}
	}

	.game-page,
	.team-players-page,
	.leaderboard-team-page {
		background: #FF044C !important;
		width: 1344px;
		height: 325px;

		.quiz-screen {

			background-color: rgba(0, 0, 0, 0) !important;

			/***********************/
			/** Question / Answer **/
			/***********************/

			&.question,
			&.answer {
				display: grid;
				grid-template-rows: 1fr auto;
				justify-content: initial;

				.media {
					display: none;
				}

				.question-text,
				.countdown-wrap {
					grid-column: 1;
					grid-row: 1;
				}

				.question-text {
					font-size: 38px !important;
					line-height: 1.2;
					background-image: linear-gradient(90deg, #B90535 0%, #FF195B 100%) !important;
					color: #fff !important;
					display: grid;
					padding-left: 108px;
					place-items: center;
				}

				.options {
					grid-row: 2;

					.option {

						background-color: #0e0169 !important;
						color: #fff !important;
						font-size: 38px !important;

						&.correct {
							background-color: #ffcc00 !important;
							color: #0e0169 !important;

							.option-text {
								color: #0e0169;
							}
						}

						.option-index {
							font-weight: normal;
						}

						.option-text {
							padding-block: 0.1em;
							font-size: 38px;
							line-height: 1.1;
						}
					}
				}
			}

			&.question {
				.countdown-wrap {
					margin-block: auto;
					place-self: start;

					.countdown {
						font-size: 42px !important;
						border-color: #000 !important;
						background-color: rgba(0, 0, 0, 0.6) !important;
						color: #fff !important;
						border-width: 5px !important;
						width: 100px !important;
						height: 100px !important;
					}
				}
			}

			/************/
			/** Scores **/
			/************/

			&.scores {

				.media,
				.team-card__img {
					display: none;
				}

				.screen-title {
					padding-block: 0.2rem;
					color: #E1DDFF;
					font-size: 30px;
					font-weight: bold;
					font-style: italic;
					line-height: 1.4;
					text-transform: uppercase;
					background-image: linear-gradient(90deg, #5306DB, #39009D);
				}

				.teams-container {
					gap: 0;
					padding: 0;

					.team-card {
						flex-basis: 50%;

						.team-name {
							padding: 0.2rem 0.3rem;
							color: #E1DDFF;
							font-size: 30px;
							font-weight: bold;
							font-style: italic;
							line-height: 1.4;
							text-transform: uppercase;
						}

						&:nth-child(1) {
							.team-name {
								background-image: linear-gradient(90deg, #FF044C, #B40134);
							}

							.team-card__content {
								background-image: linear-gradient(180deg, #4B0416, #2E040D);

								.team-card-bar .team-card-bar__inner {
									position: relative;
									isolation: isolate;
									border: none;
									--bg-image: linear-gradient(180deg, #FB0249, #D9003E);

									&::before {
										content: "";
										position: absolute;
										top: 0;
										right: 0;
										bottom: 0;
										left: 0;
										z-index: -1;
										border: 4px solid;
										border-image: var(--bg-image) 1;
									}

									.team-card-bar__col {
										background-image: var(--bg-image);
									}
								}
							}
						}

						&:nth-child(2) {
							.team-name {
								background-image: linear-gradient(90deg, #1F00DA, #11007B);
							}

							.team-card__content {
								background-image: linear-gradient(180deg, #111670, #11163E);

								.team-card-bar .team-card-bar__inner {
									position: relative;
									isolation: isolate;
									border: none;
									--bg-image: linear-gradient(180deg, #1E00CF, #1800A7);

									&::before {
										content: "";
										position: absolute;
										top: 0;
										right: 0;
										bottom: 0;
										left: 0;
										z-index: -1;
										border: 4px solid;
										border-image: var(--bg-image) 1;
									}

									.team-card-bar__col {
										background-image: var(--bg-image);
									}
								}
							}
						}

						.team-card__content {
							flex: 1 1 auto;

							.team-card__content_stats {
								flex-basis: 33%;
								font-size: 1.2rem;

								.team-card__content_stats__icon,
								.team-card__content_stats__value {
									font-size: 1.5rem;
								}
							}

							.team-card__content_stats.players {
								border-top: 1px solid #fff;
							}

							.team-card__content_stats.correct {
								flex-basis: 34%;
							}

							.team-card__content_stats.total {
								display: none;
							}

							.team-card-bar__text {
								font-size: 42px;
								font-weight: 500;
								font-style: italic;
								text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
							}
						}
					}
				}
			}
		}
	}

	/**************************/
	/** Leaderboard / Scores **/
	/**************************/

	.leaderboard-page {
		height: 100%;

		.quiz-screen {
			width: 100%;
			height: 100%;
		}
	}

	.quiz-screen.scores .screen-content {
		.rows {
			.row {
				.position {
					color: #fff;
				}

				.user-name {
					overflow: hidden;
					padding: 0 0 0 20px;
					white-space: nowrap;
					color: #fff !important;
				}

				.user-name,
				.scores,
				.total-scores {
					padding: 0 0 0 10px;
				}

				.user-team {
					color: #fff !important;
					box-sizing: content-box;
					//width: calc(1em * var(--team-name-length) / 1.51);
					width: 150px;
					padding: 0 20px;
					font-style: italic;
					text-shadow: 0.15em 0 1px var(--team-color);
					text-transform: uppercase;
				}
			}
		}
	}

	.leaderboard-page,
	.sidebar {
		.quiz-screen.scores {
			height: 100%;
		}

		.quiz-screen.scores {
			.screen-title {
				display: flex;
				justify-content: space-between;
				padding: 0 22px 0 72px;
				color: #E1DDFF;
				font-family: "Helvetica Neue", sans-serif;
				font-size: 28px;
				font-weight: bold;
				font-style: italic;
				line-height: 1.4;
				text-transform: uppercase;
				background-color: #1D3CE9;

				.title-right {
					margin-right: 33px;
				}
			}

			.screen-content {
				overflow: hidden;
				background-image: linear-gradient(180deg, #471F9A 0%, #381F74 100%);
			}

			.row {
				font-size: 22px;
				background-color: transparent;
				align-items: initial;
				font-family: "Helvetica Neue", sans-serif;
				font-weight: bold;
				background-image: linear-gradient(180deg, #4500BE 30px, #1F0055 640px);
				background-attachment: fixed;

				> * {
					display: grid;
					align-items: center;
				}

				.position {
					font-size: 1.1em;
				}

				.user-img {
					padding-right: 0.2em;
					background-color: transparent;
					background-image: none;
				}

				.total-scores {
					color: #FFCC00 !important;
				}

				.scores {
					color: #fff !important;
					font-size: 0.75em;
				}
			}
		}

		&.top5,
		&.top10 {
			width: 576px;
		}

		&.top5 {
			.quiz-screen.scores {
				height: 325px;
				overflow: hidden;

				.screen-content {
					height: 301px;
				}
			}
		}

		&.top10 {
			.quiz-screen.scores {
				.screen-content {
					height: 598px;
				}
			}
		}

		&.rounds-leaderboard-page {

			width: 1920px;
			height: 1080px;
			overflow: hidden;

			.redbull-logo {
				width: 1920px;
				height: 1080px;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1000;

				background: url("redbull-logo.png");
				background-repeat: no-repeat;
				background-position: 705px center;
				background-size: 600px;
			}

			.quiz-screen.scores {
				width: 1920px;
				overflow: hidden;

				.screen-title {
					padding-top: 20px;
					padding-bottom: 20px;

					font-size: 45px;

					.title-left {
						margin-left: 210px;
					}

					.title-right {
						margin-right: 310px;
					}
				}

				.row {
					font-size: 36px !important;

					.position {
						margin-left: 260px;
						padding-left: 0;
						font-size: 1.3em !important;
					}

					.user-team {
						margin-right: 250px;
						width: 245px;
					}
				}
			}
		}
	}

}
